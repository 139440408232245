import {action, makeObservable, observable, runInAction} from "mobx";
import {getTicketStatus, updateTickets} from "./requests";
import {Dayjs} from "dayjs";

export enum LoadingStatus {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
}

export type TicketStatus = {
  status: LoadingStatus;
  date: string | null;
  lastProcessedDate: string | null;
  total: number | null;
}

export type TicketStatusInfo = {
  lastProcessedDate: Date | null;
  total: number | null;
}

class TicketLoaderStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  isLoading = false;

  @observable
  isProcessing = false;

  @observable
  error: string | null = null;

  @observable
  dateFrom: Date | null = null;

  @observable
  dateTo: Date | null = null;

  @observable
  info: TicketStatusInfo | null = null;

  @action
  setError = (error: string | null) => this.error = error;

  @action
  setDateTo = (date: Dayjs | null) => this.dateTo = date ? date.toDate() : null;

  @action
  refresh = () => {
    this.isLoading = true;
    this.setError(null);
    return getTicketStatus()
      .then(curStatus => {
        runInAction(() => {
          console.log(curStatus)
          this.isProcessing = curStatus.status === LoadingStatus.LOADING;
          this.dateFrom = curStatus.date ? new Date(curStatus.date) : null;
          this.info = {
            lastProcessedDate: curStatus.lastProcessedDate ? new Date(curStatus.lastProcessedDate) : null,
            total: curStatus.total || null,
          };
        });
      })
      .catch((e: Error) => {
        runInAction(() => {
          this.setError(e.message);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  @action
  requestTicketLoading = async () => {
    this.setError(null);
    if (!this.dateTo) {
      this.setError('Datum bör anges')

      return false;
    }
    if (this.dateFrom && this.dateFrom.getTime() > this.dateTo.getTime()) {
      this.setError('Ogiltiga datum')

      return false;
    }

    this.isLoading = true;
    return updateTickets(this.dateTo)
      .then(() => true)
      .catch((e: Error) => {
        this.setError(e.message)
      })
      .finally(() => this.isLoading = false);
  }
}

export const ticketLoaderStore = new TicketLoaderStore();