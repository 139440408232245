import React, {FC, useState} from "react";
import {Box, Link, styled, Typography} from "@mui/material";
import {authStore} from "../../store/authStore";
import {GptChat} from "./GptChat";
import {SettingsDialog} from "./SettingsDialog";
import {Logout, Settings} from "@mui/icons-material";

const Root = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
});

const TopBar = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: '8px',
    marginRight: '8px',
})

const LinkContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    alignItems: 'center',
})

const ClickableLink = styled(Link)({
    cursor: 'pointer',
})

export const GptChatPage: FC = () => {
    const [open, setOpen] = useState(false);

    return <Root>
        <TopBar>
            <ClickableLink onClick={() => setOpen(true)}><LinkContainer><Typography>Inställningar</Typography><Settings fontSize='small'/></LinkContainer></ClickableLink>
            <ClickableLink onClick={() => authStore.logout()}><LinkContainer><Typography>Logga ut</Typography><Logout fontSize='small'/></LinkContainer></ClickableLink>
        </TopBar>
        <GptChat/>
        <SettingsDialog open={open} onClose={() => setOpen(false)}/>
    </Root>;
}