import React, {FC, useCallback, useEffect} from "react";
import {observer} from "mobx-react";
import {ticketLoaderStore, TicketStatusInfo} from "../../../store/ticketLoaderStore";
import {Button, Dialog, DialogTitle, LinearProgress, styled, Box, Typography, Tooltip} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { InfoOutlined } from '@mui/icons-material';
import {formatDate} from "../../../utils/format";

type Props = {
    open: boolean;
    onClose: () => void;
}

const Content = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    margin: '16px',
});

const ButtonArea = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
})

const InfoArea = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px',
})

const getTicketInfo = (info: TicketStatusInfo) => {
    if (!info.lastProcessedDate) return <div>Startande...</div>

    return <div>
        { info.lastProcessedDate && <div>{`Senast behandlad biljettdatum: ${formatDate(info.lastProcessedDate)}`}</div>}
        <div>{`Totalt antal biljetter för att spara: ${info.total || 0}`}</div>
    </div>;
}

export const TicketLoaderDialog: FC<Props> = observer(({open, onClose}) => {
    useEffect(() => {
        if (open) {
            ticketLoaderStore.refresh();
        }
    }, [open])

    const updateTickets = useCallback( () => {
        ticketLoaderStore.requestTicketLoading().then(result => {
            if (result) onClose();
        }).catch((e: Error) => alert(e.message));
    }, [onClose]);

    const isLoading = ticketLoaderStore.isLoading;
    const alreadyProcessing = ticketLoaderStore.isProcessing;
    const disabled = isLoading || alreadyProcessing;

    return <Dialog open={open} maxWidth="lg">
        <DialogTitle>Synkronisera biljetter</DialogTitle>
        <Content>
            { alreadyProcessing && <InfoArea>
                <Typography variant="body1">Uppdaterar redan</Typography>
                { ticketLoaderStore.info && <Tooltip title={getTicketInfo(ticketLoaderStore.info)}>
                    <InfoOutlined/>
                </Tooltip> }
                <Button onClick={ticketLoaderStore.refresh} disabled={isLoading}>Uppdatera</Button>
            </InfoArea> }
            <DatePicker disabled value={dayjs(ticketLoaderStore.dateFrom)}/>
            <DatePicker disabled={disabled} onChange={ticketLoaderStore.setDateTo}/>
            { !!ticketLoaderStore.error && <Typography variant="body1" color="error">{ticketLoaderStore.error}</Typography> }
            <ButtonArea>
                <Button onClick={updateTickets} disabled={disabled} variant="contained">Start</Button>
                <Button onClick={onClose} disabled={isLoading} variant="outlined">Cancel</Button>
            </ButtonArea>
            {isLoading && <LinearProgress />}
        </Content>
    </Dialog>
});