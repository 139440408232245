import {ContextDoc} from "../../store/gpt.types";

export type MessagePart = {
    text: string;
    url?: string;
}

export const convertQuotes = (rawResponse: string, context: ContextDoc[]) => {
    const re = '\\[[\\d]+\\]';

    const res = rawResponse.matchAll(new RegExp(re, 'g'));

    const result: MessagePart[] = [];
    let curIndex = 0;
    for (const match of res) {
        const number = +(match[0].replace('[', '').replace(']', ''));
        const nextIndex = match.index! + match[0].length;
        if (number < context.length) {
            const part = rawResponse.substring(curIndex, match.index);
            result.push({ text: part });
            const curContext = context[number];
            result.push({
                text: curContext.title,
                url: curContext.url,
            })
        } else {
            result.push({ text: rawResponse.substring(curIndex, nextIndex)})
        }
        curIndex = nextIndex;
    };

    if (curIndex <= rawResponse.length - 1) {
        result.push({ text: rawResponse.substring(curIndex) });
    }

    return result;
};