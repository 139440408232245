import React, {FC, useCallback, useEffect, useState} from 'react';
import {Box, Button, Dialog, DialogTitle, Divider, IconButton, LinearProgress, styled, TextField} from "@mui/material";
import { Close } from '@mui/icons-material';
import { promptStore } from '../../../store/promptStore';
import {observer} from "mobx-react";
import {updateArticles as updateArticlesRequest, clearDb as clearDbRequest} from "../../../store/requests";
import {TicketLoaderDialog} from "../TicketLoaderDialog/TicketLoaderDialog";

type Props = {
    open: boolean;
    onClose: () => void;
}

const CloseButton = styled(Box)({
    position: 'absolute',
    right: 8,
    top: 8,
});

const PromptField = styled(TextField)({
    minWidth: '800px',
})

const Content = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    margin: '16px',
});

const ButtonArea = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
})

export const SettingsDialog: FC<Props> = observer(({open, onClose}) => {
    useEffect(() => {
        if (open) {
            promptStore.init();
        }
    }, [open])

    const [updating, setUpdating] = useState(false);

    const [openTicketLoader, setOpenTicketLoader] = useState(false);

    const disabled = promptStore.isLoading || updating;

    const onSave = useCallback(() => {
        promptStore.update().then(onClose)
    }, [onClose])

    const onReset = useCallback(() => {
        promptStore.reset()
    }, [])

    const updateDb = useCallback( () => {
        setUpdating(true);
        updateArticlesRequest().then(count => alert(`Artiklar laddade: ${count}`)).catch((e: Error) => alert(e.message)).finally(() => setUpdating(false));
    }, []);

    const clearDb = useCallback( () => {
        setUpdating(true);
        clearDbRequest().then(() => alert('Db rensades framgångsrikt')).catch((e: Error) => alert(e.message)).finally(() => setUpdating(false));
    }, []);

    return <Dialog open={open} maxWidth="lg">
        <CloseButton>
            <IconButton onClick={onClose}>
                <Close />
            </IconButton>
        </CloseButton>
        <DialogTitle>Ange prompt</DialogTitle>
        <Content>
            <PromptField
                value={promptStore.prompt}
                onChange={promptStore.onPromptChange}
                disabled={disabled}
                helperText={promptStore.error}
                error={!!promptStore.error}
                fullWidth
                multiline
                maxRows={10}
            />
            <ButtonArea>
                <Button onClick={onReset} disabled={disabled} variant='outlined'>Återställa</Button>
                <Button onClick={onSave} disabled={disabled || !!promptStore.error} variant='contained'>Spara</Button>
            </ButtonArea>
            <Divider/>
            <ButtonArea>
                <Button onClick={updateDb} disabled={disabled} variant='outlined'>Uppdatera artiklar</Button>
                <Button onClick={() => setOpenTicketLoader(true)} disabled={disabled} variant='outlined'>Uppdatera biljetter</Button>
                <Button onClick={clearDb} disabled={disabled} variant='contained' color='error'>Rensa db</Button>
            </ButtonArea>
            <TicketLoaderDialog open={openTicketLoader} onClose={() => setOpenTicketLoader(false)}/>
            {disabled && <LinearProgress />}
        </Content>
    </Dialog>
});