import {IconButton, InputAdornment, styled, TextField} from '@mui/material';
import SendIcon from '@mui/icons-material/Send'
import { observer } from 'mobx-react';
import React from 'react';

import {gptStore} from "../../store/gptStore";
import {COLOR} from "../../theme/colors";

const ENTER_KEY_NAME = 'Enter';

const TextInput = styled(TextField)({
    width: '100%',
});

export const GptInput = observer(() => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => gptStore.setInputText(e.target.value);
    const handlePress = (e: React.KeyboardEvent<HTMLInputElement>) =>
        e.key === ENTER_KEY_NAME && gptStore.sendMessage();

    const onSendClick = () => gptStore.sendMessage();

    return (
        <TextInput
            value={gptStore.inputText}
            onChange={handleChange}
            onKeyPress={handlePress}
            placeholder={'Skriv din fråga'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={onSendClick}>
                            <SendIcon stroke={COLOR.blue} />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            disabled={gptStore.state !== 'idle'}
        />
    );
});
