import {makeObservable, observable, action, computed} from "mobx";

const STORAGE_KEY = 'zendeskGptToken';

class AuthStore {
    constructor() {
        makeObservable(this);

        this.loadFromStorage();
    }

    @observable
    token: string | null = null;

    loadFromStorage() {
        try {
            const str = localStorage.getItem(STORAGE_KEY);
            if (str) this.setAuthToken(str);
        } catch (_) {}
    }

    @action
    setAuthToken = (token: string) => {
        this.token = token;
        this.saveToStorage();
    };

    @computed
    get isLoggedIn() {
        return !!this.token;
    }

    saveToStorage = () => {
        if (this.token) {
            localStorage.setItem(STORAGE_KEY, this.token);
        } else {
            localStorage.removeItem(STORAGE_KEY);
        }
    };

    @action
    logout = () => {
        this.token = null;
        this.saveToStorage();
    };
}

export const authStore = new AuthStore();