import { action, computed, makeObservable, observable } from 'mobx';
import React from 'react';
import { authStore } from './authStore';
import { login as loginRequest} from './requests';

class LoginStore {
    constructor() {
        makeObservable(this);
    }

    @observable
    login = '';

    @observable
    password = '';

    @observable
    error: string | null = null;

    @observable
    isLoading = false;

    @action
    onLoginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.login = e.target.value;
    };

    @action
    onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.password = e.target.value;
    };

    @action
    setError = (error: string | null) => this.error = error;

    @computed
    get canSubmit() {
        return !!this.login && !!this.password;
    }

    submit = () => {
        this.isLoading = true;
        this.setError(null);
        const { login, password } = this;
        loginRequest(login, password)
            .then(token => {
                authStore.setAuthToken(token);
                this.login = '';
                this.password = '';
            })
            .catch((e: Error) => {
                this.setError(e.message)
            })
            .finally(() => this.isLoading = false);
    };
}

export const loginStore = new LoginStore();
