import React, {FC} from 'react';
import { authStore } from './store/authStore';
import {LoginPage} from "./views/LoginPage";
import {observer} from "mobx-react";
import {GptChatPage} from "./views/GptChatPage";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

export const App: FC = observer(() => {
    return <LocalizationProvider dateAdapter={AdapterDayjs}>
        { authStore.isLoggedIn ? <GptChatPage/> : <LoginPage/> }
    </LocalizationProvider>
});
