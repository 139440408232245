import {baseApiUrl} from "../api/constants";
import {Message} from "./gpt.types";
import {authStore} from "./authStore";
import {LoadingStatus, TicketStatus} from "./ticketLoaderStore";

export const mockApi = process.env.REACT_APP_MOCK_API?.toLowerCase() === 'true'

const defaultPrompt = 'Du är assistent för att svara på frågor om Bygglet. Som svar på den ställda frågan, ge ett konversationssvar med hjälp av de inskickade fragmenten av artiklar. Om du inte vet svaret, säg bara: "Jag vet inte." Försök inte hitta på ett svar. I ditt svar anger du inom hakparentes endast numret på det fragment som svaret är baserat på. Om svaret kan vara tvetydigt, be då att få förtydliga frågan.'

const getUrl = (path: string) => baseApiUrl + path;

const getHeaders = (withAuth: boolean = true) => {
    const headers: HeadersInit = {
        accept: 'application/json',
        'content-type': 'application/json',
    };

    if (withAuth) headers['authorization'] = `Bearer ${authStore.token}`;

    return headers;
};

export const sendMessage = async (chatId: string, message: string): Promise<Message> => {
    if (mockApi)
        return {
            input: 'What is integration?',
            output: 'Integration is [0] an important tool [2] to connect systems.',
            context: [
                {
                    title: 'First fragment',
                    url: 'http://google.com'
                },
                {
                    title: 'Second fragment',
                    url: 'http://google.com'
                },
                {
                    title: 'Third fragment',
                    url: 'http://google.com'
                }
            ]
        };

    const result = await fetch(getUrl('/send'), {
        method: 'post',
        headers: getHeaders(),
        body: JSON.stringify({
            chatId,
            message,
        }),
    });

    if (result.status !== 200) throw Error('Unauthorized');

    const resp = await result.json();

    return resp.message;
}

export const getConversation = async (chatId: string): Promise<Message[]> => {
    if (mockApi)
        return [];

    const result = await fetch(getUrl('/history'), {
        method: 'post',
        headers: getHeaders(),
        body: JSON.stringify({
            chatId,
        }),
    });

    if (result.status !== 200) throw Error('Unauthorized');

    return (await result.json()).messages;
}

export const login = async (login: string, password: string): Promise<string> => {
    if (mockApi)
        return 'token';

    const result = await fetch(getUrl('/login'), {
        method: 'post',
        headers: getHeaders(false),
        body: JSON.stringify({
            login,
            password,
        }),
    });

    if (result?.status !== 200) throw Error('Ogiltiga uppgifter');

    const json = await result?.json();

    return json.token;
}

export const updatePrompt = async (prompt: string | null): Promise<string> => {
    if (mockApi)
        return prompt || defaultPrompt;

    const result = await fetch(getUrl('/updatePrompt'), {
        method: 'post',
        headers: getHeaders(),
        body: JSON.stringify({
            prompt,
        }),
    });

    if (result?.status !== 200) throw Error('Kan inte uppdatera promptener');

    const json = await result?.json();

    return json.prompt;
}

export const getPrompt = async (): Promise<string> => {
    if (mockApi)
        return defaultPrompt;

    const result = await fetch(getUrl('/prompt'), {
        method: 'get',
        headers: getHeaders(),
    });

    if (result?.status !== 200) throw Error('KKan inte få prompt');

    const json = await result?.json();

    return json.prompt;
}

export const getTicketStatus = async (): Promise<TicketStatus> => {
    if (mockApi)
        return {
            status: LoadingStatus.IDLE,
            date: null,
            lastProcessedDate: null,
            total: null,
        };

    const result = await fetch(getUrl('/ticketstatus'), {
        method: 'get',
        headers: getHeaders(),
    });

    if (result?.status !== 200) throw Error('Kan inte få biljettstatus');

    const json = await result?.json();

    return json;
}

export const updateArticles = async (): Promise<number> => {
    if (mockApi)
        return 0;

    const result = await fetch(getUrl('/updatearticles'), {
        method: 'get',
        headers: getHeaders(),
    });

    if (result?.status !== 200) throw Error('Db-uppdateringsfel');

    const json = await result?.json();

    return json.data;
}

export const updateTickets = async (dateTo: Date): Promise<void> => {
    if (mockApi)
        return;

    const result = await fetch(getUrl('/updatetickets'), {
        method: 'post',
        headers: getHeaders(),
        body: JSON.stringify({
            dateTo: dateTo.toISOString(),
        }),
    });

    if (result?.status !== 200) throw Error('Det gick inte att ladda biljetter');
}

export const clearDb = async (): Promise<void> => {
    if (mockApi)
        return;

    const result = await fetch(getUrl('/cleardb'), {
        method: 'get',
        headers: getHeaders(),
    });

    if (result?.status !== 200) throw Error('Det gick inte att rensa db');
}